<template>
  <v-main app>
    <v-container class="pb-0">
      <h1>Products</h1>
      <p>We produce a wide range of products from fine art originals. Self-published artists can use our services to realise and sell their work in a variety of formats. Retail outlets such as visitor attractions, gift shops, galleries and framers can choose from our wide range of published products.</p>
      <p>If you are interested in purchasing our products, check out the <router-link to="/retailers">"Where To Buy"</router-link> page to find a reseller close to you</p>
    </v-container>
    <v-navigation-drawer clipped app fixed :permanent="$vuetify.breakpoint.mdAndUp" style="z-index: 1">
      <v-virtual-scroll :items="menu" :item-height="100" height="600" class="mt-6">
        <template v-slot="{ index, item }">
        <v-list-item class="d-flex justify-space-between" :to="`/products/${item.route}`">
          <v-list-item-avatar tile size="70px">
          <v-img max-height="70px" max-width="70px"
            :src="`https://doricmorimages.s3.eu-west-2.amazonaws.com/100px/${item.img}`" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.label"></v-list-item-title>
            <v-list-item-subtitle style="white-space:unset" v-html="item.description"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-main>
</template>
<script>
export default {
  props: {
  },
  components: {
  },
  data () {
    return {
      menu: [
        { label: 'Greeting Cards', description: 'With envelope and cello wrap', route: 'greetingcards', img: 'GCNRP75-MacLeods-Tables-1.jpg' },
        { label: 'Mugs', description: 'Porcelain (boxed)', route: 'mugs', img: 'MUGEC47-Agnes-and-Magnus-Go-For-A-Stroll-Somewhere-Over-Fife-1.jpg' },
        { label: 'Magnets', description: 'Various sizes', route: 'magnets', img: 'MAGNRP24-Coronation-Scot-(Portrait)-1.jpg' },
        { label: 'Giclee Prints', description: 'Open and Limited editions', route: 'gicleeprints', img: 'GICLKP26OP-Sky-after-Harvest.jpg' },
        { label: 'Mounted Prints', description: 'Small prints with quality cut mounts', route: 'smallmountedprints', img: 'MPEC16-Purple-Rain-Scott-Monument-Edinburgh-1.jpg' },
        { label: 'A3 Tourist Posters', description: 'Traditional railway posters', route: 'a3posters', img: 'POSTNRP01-Eilean-Donan-1.jpg' },
        { label: 'Calendars', description: 'A3, A4 and slimline calendars', route: 'calendars', img: 'POSTNRP01-Eilean-Donan-1.jpg' },
        { label: 'Postcards', description: 'Cards with various reverse side options', route: 'postcards', img: 'POSTNRP01-Eilean-Donan-1.jpg' }
      ]
    }
  },
  async created () {
  },
  computed: {
  },
  watch: {},
  methods: {
  }
}
</script>
<style lang="scss" scoped>

</style>
